import React from "react";
// import "./style.css";

function Footer (){
    return (
        <footer>
            &#169; 2020 Kate Dorse
        </footer>
    );
}

export default Footer;